import { Button } from '@Infowijs-eng/component-library/components'
import useAuthenticatedRequest from '@Infowijs-eng/component-library/hooks/useAuthenticatedRequest'
import { ArrowsLoop, Person } from '@Infowijs-eng/component-library/icons'
import { getHoyEndpoint, serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import addNotification from '../actions/notificationCenter/addNotification'
import getFullName from '../modules/getFullName'
import { STATUS_WARNING } from './StatusIcon'

function ImpersonateAsAdminButton({
  customerProduct,
  customerId,
}) {
  const authenticatedRequest = useAuthenticatedRequest()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [adminName, setAdminName] = useState(null)

  async function getAdminRoleId() {
    const userRolesResponse = await authenticatedRequest(`${serviceEndpoints.users}/roles/customers/${customerId}`, {
      method: 'get',
    })
    if (!userRolesResponse || !userRolesResponse.data) {
      return null
    }
    const userRolesData = (userRolesResponse && userRolesResponse.data) || []
    const adminRoleIndex = userRolesData.findIndex((role) => role.legacyRole === 'admin')
    if (adminRoleIndex < 0) {
      return null
    }
    const adminRole = userRolesData[adminRoleIndex]
    return adminRole.id
  }

  async function getUsersForRoleId(roleId) {
    const adminUserResponse = await authenticatedRequest(`${serviceEndpoints.users}`, {
      method: 'get',
      params: {
        page: 0,
        sort: 'lastUpdatedAt:desc',
        customerId: `eq:${customerId}`,
        userRoleId: `eq:${roleId}`,
      },
    })

    return (adminUserResponse && adminUserResponse.data) || null
  }

  async function retrieveAdminUserCustomerProductKey() {
    const adminRoleId = await getAdminRoleId()
    if (!adminRoleId) {
      return null
    }

    const users = await getUsersForRoleId(adminRoleId)
    if (!users) {
      return null
    }

    let userCustomerProductKeyId = null

    // eslint-disable-next-line no-restricted-syntax
    for await (const user of users) {
      const userWithCustomerProductKey = await authenticatedRequest(`${serviceEndpoints.users}/${user.id}`, {
        params: {
          include: 'userCustomerProductKey',
        },
      })

      if (!userWithCustomerProductKey
        || !userWithCustomerProductKey.data
        || !userWithCustomerProductKey.data.metadata
        || !userWithCustomerProductKey.data.metadata.lastSeen
        || !userWithCustomerProductKey.data.metadata.lastSeen.hoy_web
      ) {
        // eslint-disable-next-line no-continue
        continue
      }

      const matchingUserCustomerProductKeyForCustomer = Object
        .entries(userWithCustomerProductKey.included.userCustomerProductKey)
        .find((entry) => {
          const customerProductId = entry[1] && entry[1].customerProductId
          return customerProductId && customerProductId === customerProduct.id
        })

      if (!matchingUserCustomerProductKeyForCustomer) {
        // eslint-disable-next-line no-continue
        continue
      }

      // eslint-disable-next-line prefer-destructuring
      userCustomerProductKeyId = matchingUserCustomerProductKeyForCustomer[0]
      if (userCustomerProductKeyId) {
        setAdminName(getFullName(user))
        break
      }
    }
    return userCustomerProductKeyId
  }

  return (
    <Button
      onClick={async () => {
        setIsLoading(true)
        const adminUserCustomerProductKeyId = await retrieveAdminUserCustomerProductKey()

        if (adminUserCustomerProductKeyId === null) {
          setIsDisabled(true)
          setIsLoading(false)
          dispatch(addNotification({
            title: 'This customer product does not have an admin suitable for quick logins',
            status: STATUS_WARNING,
          }))
          return
        }

        setIsLoggingIn(true)

        const response = await authenticatedRequest(
          `${serviceEndpoints.sessions}/transfer/impersonate`,
          {
            method: 'post',
            data: {
              userCustomerProductKeyId: adminUserCustomerProductKeyId,
            },
          },
        )

        if (!response || !response.data || !response.data.transferToken) {
          setIsDisabled(true)
          setIsLoading(false)
          setIsLoggingIn(false)
          dispatch(addNotification({
            title: `Could not create impersonation session for ${adminName}`,
            status: STATUS_WARNING,
          }))
          return
        }
        const { transferToken } = response.data
        setIsLoading(false)
        await new Promise((resolve) => { setTimeout(resolve, 1500) })

        window.open(`${getHoyEndpoint(customerProduct.name)}?token=${transferToken}`)
        setIsLoggingIn(false)
      }}
      disabled={isLoading || isDisabled || isLoggingIn}
      leadingAddon={isLoading || isLoggingIn ? <ArrowsLoop className="animate-spin" /> : <Person />}
      small
      neutral
      className="ml-2"
    >
      <span
        className="truncate w-full max-w-[16rem]"
      >
        {isLoggingIn && adminName ? `Logging in as ${adminName}` : 'Login as Admin'}
      </span>
    </Button>
  )
}

ImpersonateAsAdminButton.propTypes = {
  customerProduct: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  customerId: PropTypes.string.isRequired,
}

export default ImpersonateAsAdminButton
