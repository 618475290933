import {
  Skeleton,
} from '@Infowijs-eng/component-library/components'
import {
  Bolt,
  Eye,
  PlusCircle,
  TwoVerticalLines,
} from '@Infowijs-eng/component-library/icons'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import SectionBody from '../components/SectionBody'
import SectionTitle from '../components/SectionTitle'
import Timeline from '../components/Timeline'
import TimelineEvent from '../components/TimelineEvent'
import customerProductDesignStructure from '../modules/customerProductDesignStructure'
import MetadataEditor from '../components/MetadataEditor/MetadataEditor'
import getCustomerProductMetadataStructure from '../modules/getCustomerProductMetadataStructure'
import getCustomerProduct from '../selectors/customerProducts/getCustomerProduct'
import deployCustomerProduct from '../actions/customerProducts/deployCustomerProduct'
import CustomerProductContextScreen from './CustomerProductContextScreen'

function CustomerProductDetailScreen() {
  const { customerProductId } = useParams()
  const dispatch = useDispatch()

  const customerProduct = useSelector((state) => (
    getCustomerProduct(state, { customerProductId })
  ))

  const actions = []

  if (customerProduct && !customerProduct.deployedAt) {
    actions.push({
      children: 'Deploy',
      leadingAddon: <Bolt />,
      permissions: ['customers.customerProducts.deploy'],
      onClick: () => dispatch(deployCustomerProduct(customerProduct.id, dayjs())),
      key: 'deploy.customerProduct',
    })
  }

  if (customerProduct && customerProduct.deployedAt && dayjs(customerProduct.deployedAt).isBefore(dayjs())) {
    actions.push({
      children: 'Undeploy',
      leadingAddon: <TwoVerticalLines />,
      permissions: ['customers.customerProducts.deploy'],
      onClick: () => dispatch(deployCustomerProduct(customerProduct.id, null)),
      key: 'deploy.customerProduct',
    }, {
      children: 'Preview',
      leadingAddon: <Eye />,
      href: `https://${customerProduct && customerProduct.name}.hoyapp.nl`,
      target: '_blank',
      permissions: [],
      key: 'preview.customerProduct',
    })
  }

  return (
    <CustomerProductContextScreen
      secondaryActions={actions}
    >
      <div className="pt-4 space-y-4 xl:space-y-0 xl:gap-4 xl:grid xl:grid-cols-12">
        {!customerProduct && (
          <section>
            <SectionTitle><Skeleton /></SectionTitle>
          </section>
        )}
        <div className="space-y-4 xl:col-span-7">
          {customerProduct
            && customerProduct.design
            && Object.keys(customerProduct.design).length > 0 && (
            <section aria-labelledby="design">
              <SectionTitle>Design 🎨</SectionTitle>
              <SectionBody>
                <MetadataEditor
                  metadata={customerProduct.design}
                  structure={customerProductDesignStructure}
                />
              </SectionBody>
            </section>
          )}
          {customerProduct
            && customerProduct.metadata
            && Object.keys(customerProduct.metadata).length > 0 && (
            <section aria-labelledby="metadata">
              <SectionTitle>Metadata</SectionTitle>
              <SectionBody>
                <MetadataEditor
                  metadata={customerProduct.metadata}
                  structure={getCustomerProductMetadataStructure(customerProduct.type)}
                />
              </SectionBody>
            </section>
          )}
        </div>
        <Timeline className="xl:col-span-5">
          {(customerProduct
              && customerProduct.deployedAt
              && dayjs(customerProduct.deployedAt).isBefore(dayjs()))
            && (
              <TimelineEvent
                Icon={Bolt}
                label="Deployed"
                date={customerProduct.deployedAt}
              />
            )}
          <TimelineEvent
            Icon={PlusCircle}
            label={
                customerProduct
                && customerProduct.createdBy
                && customerProduct.createdBy.firstName
                /* eslint-disable-next-line max-len */
                && `${customerProduct.createdBy.firstName} ${customerProduct.createdBy.lastName} created ${customerProduct.title}`
              }
            date={customerProduct && customerProduct.createdAt}
            eventLineOff
          />
        </Timeline>
      </div>
    </CustomerProductContextScreen>
  )
}

export default CustomerProductDetailScreen
